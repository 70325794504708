<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h4>Stock par livreur</h4>

        <DataTable
          :value="produits"
          :paginator="true"
          class="p-datatable-gridlines"
          :rows="10"
          dataKey="id"
          :rowHover="true"
          v-model:filters="filters1"
          :loading="loading1"
          :filters="filters1"
          responsiveLayout="scroll"
          :globalFilterFields="['nomProduit', 'numSerie', 'stock', 'full_name']"
        >
          <template #header>
            <div class="flex justify-content-between flex-column sm:flex-row">
              <span class="p-input-icon-left mb-2">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters1['global'].value"
                  placeholder="Keyword Search"
                  style="width: 100%"
                />
                
              </span>
              <span class="p-input-icon-left mb-2">
                <Calendar v-model="datefilter" />
                </span>
            </div>
          </template>
          <template #empty> Aucun client trouvé. </template>
          <template #loading>
            Chargement des données produits. Veuillez patienter.
          </template>
          <Column
            field="nomProduit"
            header="Produit"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.nomProduit }}
            </template>
          </Column>
          <Column
            field="numSerie"
            header="série"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.numSerie }}
            </template>
          </Column>
          <Column
            field="stock"
            header="Stock"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.stock }}
            </template>
          </Column>
          <Column
            field="full_name"
            header="livreur"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.full_name }}
            </template>
          </Column>

          <Column header="Image">
            <template #body="slotProps">
              <img
                :src="
                  url +
                  slotProps.data.imageProduit
                "
                class="shadow-2"
                width="60"
              />
            </template>
          </Column>

          <!-- <Column
            field="etat"
            header="Etat"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              <Button
                v-if="data.etat == 1"
                @click="changestatuts(data.id, 0)"
                icon="pi pi-check"
                class="p-button-raised p-button-success"
                disabled
              />
              <Button
                v-else
                @click="changestatuts(data.id, 1)"
                icon="pi pi-times"
                class="p-button-raised p-button-danger"
                disabled
              />
            </template>
          </Column> -->
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import { FilterMatchMode, FilterOperator } from "primevue/api";
export default {
  data() {
    return {
      url:null,
      Permissions: { update: false, delete: false, add: false, show: false },
      produits: [],
      datefilter: null,
      filters1: null,
      loading1: true,
    };
  },
  watch: {
    datefilter() {
     
      this.showAllproduits();
    }
  },

  mounted() {
    this.url = this.$Gurl;
    // this.Permissions.update =
    //   localStorage.permissionNames.includes("Liste stock camion");
    // this.Permissions.delete =
    //   localStorage.permissionNames.includes("Liste stock camion");
    // this.Permissions.add =
    //   localStorage.permissionNames.includes("Liste stock camion");
    this.Permissions.show =
      localStorage.permissionNames.includes("Liste stock camion");
    if (this.Permissions.show) {
      this.showAllproduits();
    } else {
      this.$router.push("/");
    }
  },

  created() {
    // if (localStorage.token == "" || localStorage.token == null) {
    //   this.$router.push("/login");
    // }
    this.initFilters1();
  },
  methods: {
    handleDateChange(newValue) {
      // newValue contains the new selected date
      console.log('Selected Date:', newValue);
      this.showAllproduits();
    },
    showAllproduits() {
      
      if (this.datefilter == null) {
        
        this.datefilter = moment().format('YYYY-MM-DD')
        console.log('dazet1',this.datefilter)
      } else {
        this.datefilter = moment(this.datefilter).format('YYYY-MM-DD')
        console.log('dazet1',this.datefilter)
      }
      axios
        .post("liststockCamion",{date:this.datefilter})
        .then((response) => {
          this.produits = response.data.camionStock;
          console.log(response);
          this.loading1 = false;
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },

    initFilters1() {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        "country.name": {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        date: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
        },
        balance: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        status: {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS },
      };
    },
    clearFilter1() {
      this.initFilters1();
    },

    formatCurrency(value) {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    formatDate(value) {
      return value.toLocaleDateString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    },
    calculateCustomerTotal(name) {
      let total = 0;
      if (this.customer3) {
        for (let customer of this.customer3) {
          if (customer.representative.name === name) {
            total++;
          }
        }
      }

      return total;
    },
  },
};
</script>
<style scoped lang="scss">
@import "../assets/demo/badges.scss";
@import "../assets/demo/badges.scss";
::v-deep(.p-datatable-frozen-tbody) {
  font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
  font-weight: bold;
}
.is-invalid {
  border-color: #e24c4c;
}
.p-inputtext.p-invalid.p-component {
  border-color: #e24c4c;
}
</style>